footer .footer-content {
  padding-left: 20px;
  padding-right: 20px; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    footer .footer-content {
      padding-left: 0;
      padding-right: 0; } }

.site .navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  height: 90px;
  border-bottom: 1px solid #40eda4;
  position: sticky;
  top: 0;
  z-index: 999; }
  .site .navigation.is-stuck {
    box-shadow: 0px 0px 6px 0px rgba(0, 245, 143, 0.3); }

.site .navigation .navigation-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  width: 1024px; }
  .site .navigation .navigation-content .logo {
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-top: 4px;
    width: 100px;
    margin-right: 70px; }
    .site .navigation .navigation-content .logo a {
      border-bottom: none; }
    .site .navigation .navigation-content .logo img {
      width: 100%;
      height: 38px; }
  .site .navigation .navigation-content .link {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 0.84em;
    min-width: 60px;
    margin-right: 30px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    letter-spacing: 0.08em;
    font-weight: 600; }
    .site .navigation .navigation-content .link a {
      text-decoration: none;
      color: #444; }
    .site .navigation .navigation-content .link a:hover {
      color: #05c977; }
    .site .navigation .navigation-content .link a:focus {
      outline: none; }
    .site .navigation .navigation-content .link .link-text {
      height: 46px; }
    .site .navigation .navigation-content .link:hover {
      border-bottom: 2px solid rgba(64, 237, 164, 0.3); }
    .site .navigation .navigation-content .link.contact {
      width: 140px;
      font-weight: 600;
      border: 1px solid rgba(244, 29, 87, 0.6);
      border-radius: 2px; }
      .site .navigation .navigation-content .link.contact .link-text {
        color: #f41d57; }
      .site .navigation .navigation-content .link.contact:hover {
        background: rgba(244, 29, 87, 0.8);
        border: 1px solid rgba(244, 29, 87, 0.9); }
        .site .navigation .navigation-content .link.contact:hover .link-text {
          color: white; }
  .site .navigation .navigation-content .spacer {
    flex: 1 0 auto; }

a.button, button.button {
  display: inline-flex;
  flex-shrink: 1;
  flex-grow: 0;
  width: auto;
  min-height: 48px;
  min-width: 160px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  text-transform: uppercase;
  font-family: 'IBM Plex Sans', sans-serif;
  background: #8521ff;
  border: 1px solid #8521ff;
  color: #fff;
  cursor: pointer; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    a.button, button.button {
      background: none;
      color: #9242f4; }
      a.button:hover, button.button:hover {
        background: #8521ff;
        border: 1px solid #8521ff;
        color: #fff; } }
  a.button .button-content, button.button .button-content {
    font-weight: 600;
    font-size: 0.85em;
    letter-spacing: 0.04em; }

button.button {
  font-size: 1.0em; }

footer {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 1;
  background: #1d1920;
  padding-top: 70px;
  padding-bottom: 20px;
  font-size: 0.85em; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    footer {
      height: 180px;
      padding-top: 60px;
      padding-bottom: 40px; } }
  footer .footer-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: #fff;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 0.9em; }
    @media screen and (min-width: 992px) and (orientation: landscape) {
      footer .footer-content {
        max-width: 1024px;
        margin: 0 auto; } }
    footer .footer-content .content {
      display: flex;
      flex-direction: column;
      flex-grow: 1; }
      @media screen and (min-width: 992px) and (orientation: landscape) {
        footer .footer-content .content {
          width: 100%;
          flex-direction: row; } }
      footer .footer-content .content .footer-logo img {
        width: 60px;
        opacity: 0.95; }
        @media screen and (min-width: 992px) and (orientation: landscape) {
          footer .footer-content .content .footer-logo img {
            width: 80px; } }
      footer .footer-content .content .spacer {
        flex: 1 0 auto; }
      footer .footer-content .content .footer-links {
        width: auto;
        margin-left: 2px; }
        @media screen and (min-width: 992px) and (orientation: landscape) {
          footer .footer-content .content .footer-links {
            margin-left: 40px; } }
        footer .footer-content .content .footer-links.yakka {
          margin-top: 20px; }
          @media screen and (min-width: 992px) and (orientation: landscape) {
            footer .footer-content .content .footer-links.yakka {
              margin-top: 0; } }
        footer .footer-content .content .footer-links.outreach {
          margin-top: 20px; }
          @media screen and (min-width: 992px) and (orientation: landscape) {
            footer .footer-content .content .footer-links.outreach {
              margin-top: 0; } }
        footer .footer-content .content .footer-links.contact {
          margin-top: 30px;
          margin-bottom: 20px;
          padding-left: 0; }
          @media screen and (min-width: 992px) and (orientation: landscape) {
            footer .footer-content .content .footer-links.contact {
              margin-top: 0;
              margin-bottom: 0;
              padding-left: 40px; } }
        footer .footer-content .content .footer-links .footer-link:not(:first-child) {
          margin-top: 6px; }
        footer .footer-content .content .footer-links .footer-link a {
          font-family: 'Roboto Condensed', sans-serif;
          text-transform: uppercase;
          display: inline-block;
          color: rgba(255, 255, 255, 0.65);
          border-bottom: 1px dotted rgba(255, 255, 255, 0.22);
          letter-spacing: 0.05em;
          font-size: 0.9em; }
          footer .footer-content .content .footer-links .footer-link a:hover {
            color: #fff;
            border-bottom: 1px solid rgba(64, 237, 164, 0.75); }
        footer .footer-content .content .footer-links .footer-link.contact {
          font-size: 1.65em; }
          footer .footer-content .content .footer-links .footer-link.contact a {
            color: rgba(255, 255, 255, 0.8); }
            footer .footer-content .content .footer-links .footer-link.contact a:hover {
              color: #fff; }
          footer .footer-content .content .footer-links .footer-link.contact .contact-subtext {
            margin-top: 5px;
            font-size: 0.6em;
            color: rgba(255, 255, 255, 0.65); }
            footer .footer-content .content .footer-links .footer-link.contact .contact-subtext.first {
              margin-top: 8px; }
        footer .footer-content .content .footer-links .footer-link .text {
          margin-bottom: 3px; }
    footer .footer-content .fine-print {
      text-align: left;
      font-family: "IBM Plex Sans", sans-serif;
      font-size: 0.65em;
      color: rgba(255, 255, 255, 0.45);
      text-transform: uppercase;
      letter-spacing: 0.05em;
      margin-top: 10px;
      margin-bottom: 20px; }
      @media screen and (min-width: 992px) and (orientation: landscape) {
        footer .footer-content .fine-print {
          text-align: right;
          margin-top: 40px;
          margin-bottom: 0; } }
      footer .footer-content .fine-print .yakka-em {
        font-weight: 500;
        color: rgba(255, 255, 255, 0.75); }

.yakka-source-logo {
  margin-top: 5px; }

html, body {
  background-color: #fff;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  font-size: 1.05em; }

.spacer {
  flex: 1 0 auto; }

a {
  text-decoration: none;
  color: #222;
  border-bottom: 1px dotted #ddd;
  font-weight: 400; }
  a:hover {
    color: #9242f4;
    border-bottom: 1px dotted #e2ccff; }

h1.bold {
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
  letter-spacing: -0.01em;
  margin-bottom: 25px;
  color: #9242f4;
  font-size: 1.8em;
  font-weight: 600;
  transform: scale(1, 1.3);
  border-bottom: 1px solid rgba(146, 66, 244, 0.118); }

h1 {
  font-family: 'Montserrat', sans-serif;
  color: #222;
  font-size: 1.45em;
  display: inline-block;
  padding-bottom: 8px;
  letter-spacing: 0.01em;
  transform: scale(1, 1.3);
  font-weight: 600;
  border-bottom: 1px solid #e4e4e4; }

.site {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }
  .site .site-content {
    font-family: "IBM Plex Sans", sans-serif; }
    @media screen and (min-width: 992px) and (orientation: landscape) {
      .site .site-content {
        flex-shrink: 0;
        flex-grow: 1;
        padding-top: 40px; } }
    @media screen and (min-width: 992px) and (orientation: landscape) {
      .site .site-content .site-content-wrapper {
        max-width: 1024px;
        margin: 0 auto; } }

a.no-decoration {
  border-bottom: none; }

.medium-bold {
  font-weight: 500; }

.bold {
  font-weight: 700; }
